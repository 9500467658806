export default {
  __name: 'financials',
  emits: ["closechart"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    onMounted(function () {
      emit("closechart");
    });
    return {
      __sfc: true,
      emit: emit
    };
  }
};